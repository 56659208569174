import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { Tile, TileBody } from '@cof/lighthouse-component-library';
import PropTypes from 'prop-types';

import './CardTileSecureLH.scss';
import secureEyeIcon from '../../assets/images/pdp-secure-eye.svg';
import secureNotificationIcon from '../../assets/images/bell-notification-icon.svg';

const CardTileSecureLH = injectIntl(
  ({
    intl,
    titleLeft,
    descriptionLeft,
    titleRight,
    descriptionRight,
    imgLeft = secureEyeIcon,
    imgRight = secureNotificationIcon,
  }) => {
    return (
      <div className="card-tile-secure-container-lh" data-testid="card-tile-secure-container">
        <h3 className="secure-title-container-lh">
          {intl.formatMessage({ id: 'pages.pdp.content.more-than-just.sub-title' })}
        </h3>
        <div className="card-tiles-wrapper-lh">
          <Tile>
            <TileBody className="secure-eye-container-lh">
              <img src={imgLeft} alt="" />
              <h4>{titleLeft}</h4>
              <p>{descriptionLeft}</p>
            </TileBody>
          </Tile>
          <Tile>
            <TileBody className="secure-notification-container-lh">
              <img src={imgRight} alt="" />
              <h4>{titleRight}</h4>
              <p>{descriptionRight}</p>
            </TileBody>
          </Tile>
        </div>
      </div>
    );
  },
);

CardTileSecureLH.propTypes = {
  intl: intlShape,
  titleLeft: PropTypes.string.isRequired,
  descriptionLeft: PropTypes.string.isRequired,
  titleRight: PropTypes.string.isRequired,
  descriptionRight: PropTypes.string.isRequired,
  imgLeft: PropTypes.string,
  imgRight: PropTypes.string,
};

export default CardTileSecureLH;

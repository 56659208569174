import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { AccordionItem } from '@cof/lighthouse-component-library';
import { LayoutWrapper } from '@cof/lighthouse-component-library';
import { Link } from '@cof/lighthouse-component-library';
import { ReviewCard } from '@cof/lighthouse-component-library';
import { Tile } from '@cof/lighthouse-component-library';

import Header from '../components/HeaderLH';
import { LayoutPDP } from '../components/LayoutPDP';
import PdpFooterLH from '../components/Pdp/PdpFooterLH';
import CardTileSecureLH from '../components/CardTileSecureLH';
import { getContentKeyUtil } from '../lib/utils';
import { appendExternalID } from '../lib/urls';

import blueCheck from '../assets/images/blue-check.svg';
import stepOne from '../assets/images/step-one.svg';
import stepTwo from '../assets/images/step-two.svg';
import stepThree from '../assets/images/step-three.svg';
import toolkitHeroDesktop from '../assets/images/toolkit-hero-desktop.png';
import toolkitHeroTablet from '../assets/images/toolkit-hero-tablet.png';
import toolkitHeroMobile from '../assets/images/toolkit-hero-mobile.png';
import MobileAppDesktop from '../assets/images/app-product-suite-desktop.png';
import MobileAppTablet from '../assets/images/app-product-suite-tablet.png';
import MobileAppMobile from '../assets/images/app-product-suite-mobile.png';
import creditKeeperGif from '../assets/images/product-suite-credit-score.gif';
import qrCode from '../assets/images/product-suite-qr-code.png';
import appStore from '../assets/images/product-suite-apple-app-store.png';
import googlePlayStore from '../assets/images/product-suite-google-play-store.png';
import lifeAndCreditDesktop from '../assets/images/life-and-credit-desktop.png';
import lifeAndCreditTablet from '../assets/images/life-and-credit-tablet.png';
import lifeAndCreditMobile from '../assets/images/life-and-credit-mobile.png';
import './credit-success-toolkit.scss';
import SEO from '../components/SEO/SEO';

const getContentKey = getContentKeyUtil('pages.credit-success-toolkit');

const CreditToolkitHeader = () => {
  return (
    <div className="credit-toolkit-container">
      <div className="credit-toolkit-content">
        <h1 data-testid="header-tile-title">
          <FormattedMessage
            id="pages.credit-success-toolkit.credit-toolkit.title"
            values={{ credit: <span className="white-text">Credit</span> }}
          />
        </h1>
        <p>
          <FormattedMessage id="pages.credit-success-toolkit.credit-toolkit.description" />
        </p>
      </div>
      <div className="credit-toolkit-image-box">
        <img src={toolkitHeroDesktop} alt="" className="credit-toolkit-desktop" />
        <img src={toolkitHeroTablet} alt="" className="credit-toolkit-tablet" />
        <img src={toolkitHeroMobile} alt="" className="credit-toolkit-mobile" />
      </div>
    </div>
  );
};

const ApprovalCardTiles = ({ intl }) => {
  return (
    <div className="approval-card-tiles-container">
      <h2>
        <FormattedMessage id="pages.credit-success-toolkit.approval-card-tiles.title" />
      </h2>
      <p>
        <FormattedMessage id="pages.credit-success-toolkit.approval-card-tiles.description" />
      </p>
      <div className="approval-content">
        <div className="approval-steps-card-tiles">
          <Tile className="approval-step-tile">
            <img src={stepOne} alt="Step One" />
            <h3>
              <FormattedMessage id="pages.credit-success-toolkit.approval-card-tiles.card-tiles.tile1.step" />
            </h3>
            <p data-testid="approval-tile-one">
              <FormattedMessage id="pages.credit-success-toolkit.approval-card-tiles.card-tiles.tile1.description" />
            </p>
          </Tile>
          <Tile className="approval-step-tile">
            <img src={stepTwo} alt="Step Two" />
            <h3>
              <FormattedMessage id="pages.credit-success-toolkit.approval-card-tiles.card-tiles.tile2.step" />
            </h3>
            <p>
              <FormattedMessage id="pages.credit-success-toolkit.approval-card-tiles.card-tiles.tile2.description" />
            </p>
          </Tile>
          <Tile className="approval-step-tile">
            <img src={stepThree} alt="Step Three" />
            <h3>
              <FormattedMessage id="pages.credit-success-toolkit.approval-card-tiles.card-tiles.tile3.step" />
            </h3>
            <p>
              <FormattedMessage id="pages.credit-success-toolkit.approval-card-tiles.card-tiles.tile3.description" />
            </p>
          </Tile>
        </div>
        <Link
          to={appendExternalID(
            intl.formatMessage({ id: `pages.credit-success-toolkit.approval-card-tiles.pre-approved-link` }),
          )}
          isButton
          data-testid="approval-button"
        >
          <FormattedMessage id="pages.credit-success-toolkit.approval-card-tiles.preapprove-button" />
        </Link>
      </div>
    </div>
  );
};

ApprovalCardTiles.propTypes = {
  intl: intlShape,
};

const CardRequirements = ({ intl }) => {
  return (
    <section className="card-requirements">
      <div className="left-content">
        <div className="left-content-info">
          <h2>
            <FormattedMessage id="pages.credit-success-toolkit.card-requirements.title" />
          </h2>
          <p>
            <FormattedMessage id="pages.credit-success-toolkit.card-requirements.description" />
          </p>
        </div>
      </div>
      <div className="right-content">
        <ol>
          <li>
            <img src={blueCheck} alt="" />
            <h3>{intl.formatMessage({ id: `pages.credit-success-toolkit.card-requirements.p1` })}</h3>
            <span>{intl.formatMessage({ id: `pages.credit-success-toolkit.card-requirements.desc1` })}</span>
          </li>
          <li>
            <img src={blueCheck} alt="" />
            <h3>{intl.formatMessage({ id: `pages.credit-success-toolkit.card-requirements.p2` })}</h3>
            <span>{intl.formatMessage({ id: `pages.credit-success-toolkit.card-requirements.desc2` })}</span>
          </li>
          <li>
            <img src={blueCheck} alt="" />
            <h3>{intl.formatMessage({ id: `pages.credit-success-toolkit.card-requirements.p3` })}</h3>
            <span>{intl.formatMessage({ id: `pages.credit-success-toolkit.card-requirements.desc3` })}</span>
          </li>
        </ol>
      </div>
    </section>
  );
};

CardRequirements.propTypes = {
  intl: intlShape,
};

const CreditKeeperInfo = ({ intl }) => {
  return (
    <div className="credit-keeper-info-container">
      <div className="credit-keeper-image-box">
        <img src={creditKeeperGif} alt="" />
      </div>
      <div className="credit-keeper-info-content">
        <h2>
          <FormattedMessage id="pages.credit-success-toolkit.credit-keeper.title" />
        </h2>
        <h3>
          <FormattedMessage id="pages.credit-success-toolkit.credit-keeper.p1" />
        </h3>
        <p>
          <FormattedMessage id="pages.credit-success-toolkit.credit-keeper.desc1" />
        </p>
        <h3>
          <FormattedMessage id="pages.credit-success-toolkit.credit-keeper.p2" />
        </h3>
        <p className="second-description">
          <FormattedMessage id="pages.credit-success-toolkit.credit-keeper.desc2" />
        </p>
        <Link
          to={appendExternalID(
            intl.formatMessage({ id: `pages.credit-success-toolkit.credit-keeper.credit-keeper-link` }),
          )}
          accessibilityLabel={intl.formatMessage({
            id: `pages.credit-success-toolkit.credit-keeper.credit-keeper-accessibility-label`,
          })}
          isButton
          preset="secondary"
          newTab
          referrerTracking
        >
          <FormattedMessage id="pages.credit-success-toolkit.credit-keeper.button" />
        </Link>
      </div>
    </div>
  );
};

CreditKeeperInfo.propTypes = {
  intl: intlShape,
};

const MobileAppTile = ({ intl }) => {
  return (
    <div className="mobile-info-container">
      <div className="mobile-content">
        <h2>
          <FormattedMessage id="pages.credit-success-toolkit.mobile.title" />
        </h2>
        <p>
          <FormattedMessage id="pages.credit-success-toolkit.mobile.description" />
        </p>
        <div className="mobile-app-download-container">
          <div className="desktop-view">
            <img src={qrCode} alt="QR code to download the Capital One mobile app" aria-describedby="qr-code-desc" />
            <p id="qr-code-desc">
              <FormattedMessage id="pages.credit-success-toolkit.mobile.qr-code-accessiblity-label" />
            </p>
            <p>
              <FormattedMessage id="pages.credit-success-toolkit.mobile.button" />
            </p>
          </div>
          <div className="tablet-view">
            <Link
              to={intl.formatMessage({ id: `pages.credit-success-toolkit.mobile.app-store` })}
              accessibilityLabel={intl.formatMessage({
                id: `pages.credit-success-toolkit.mobile.app-store-accessibility-label`,
              })}
              newTab
              data-testid="apple-app-store-link"
            >
              <img src={appStore} alt="Apple App Store" className="apple-app-store" />
            </Link>
            <Link
              to={intl.formatMessage({ id: `pages.credit-success-toolkit.mobile.google-play-store` })}
              accessibilityLabel={intl.formatMessage({
                id: `pages.credit-success-toolkit.mobile.google-play-accessibility-label`,
              })}
              newTab
              data-testid="google-play-store-link"
            >
              <img src={googlePlayStore} alt="Google Play Store" className="google-play-store" />
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile-image-box">
        <img src={MobileAppDesktop} alt="" className="app-desktop" />
        <img src={MobileAppTablet} alt="" className="app-tablet" />
        <img src={MobileAppMobile} alt="" className="app-mobile" />
      </div>
    </div>
  );
};

MobileAppTile.propTypes = {
  intl: intlShape,
};

const CreditManagement = ({ intl }) => {
  return (
    <div className="credit-management-info-container">
      <div className="credit-management-image-box">
        <img src={lifeAndCreditDesktop} alt="" className="life-and-credit-desktop" />
        <img src={lifeAndCreditTablet} alt="" className="life-and-credit-tablet" />
        <img src={lifeAndCreditMobile} alt="" className="life-and-credit-mobile" />
      </div>
      <div className="credit-management-content">
        <h2>
          <FormattedMessage id="pages.credit-success-toolkit.credit-management.title" />
        </h2>
        <p>
          <FormattedMessage id="pages.credit-success-toolkit.credit-management.description" />
        </p>
        <Link
          to={appendExternalID(
            intl.formatMessage({ id: `pages.credit-success-toolkit.credit-management.credit-blog-link` }),
          )}
          accessibilityLabel={intl.formatMessage({
            id: `pages.credit-success-toolkit.credit-management.credit-blog-accessibility-label`,
          })}
          isButton
          preset="secondary"
          newTab
          referrerTracking
        >
          <FormattedMessage id="pages.credit-success-toolkit.credit-management.button" />
        </Link>
      </div>
    </div>
  );
};

CreditManagement.propTypes = {
  intl: intlShape,
};

const ReviewsBanner = ({ intl }) => {
  const reviewCardContent = (
    <>
      <span className="gray-text testimonial-desktop">
        {intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.author' })} –{' '}
        {intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.customer' })}
      </span>
      <span className="gray-text testimonial-mobile">
        {intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.author' })}
      </span>
      <span className="gray-text testimonial-mobile">
        {intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.customer' })}
      </span>
    </>
  );
  return (
    <section className="pdp-reviews-banner">
      <h2>{intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.title' })}</h2>
      <div className="pdp-reviews-content">
        <div className="pdp-reviews-content-box">
          <ReviewCard
            className="review-card-no-stars"
            author={reviewCardContent}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.testimonial' })}
          />
        </div>
      </div>
      <div className="review-card-container">
        <div>
          <ReviewCard
            author={intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.a1' })}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.t1' })}
          />
        </div>
        <div>
          <ReviewCard
            author={intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.a2' })}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.t2' })}
          />
        </div>
        <div>
          <ReviewCard
            author={intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.a3' })}
            rating={5}
            starAltText="5/5 star rating"
            description={intl.formatMessage({ id: 'pages.credit-success-toolkit.reviews.t3' })}
          />
        </div>
      </div>
    </section>
  );
};

ReviewsBanner.propTypes = {
  intl: intlShape,
};

const QuickCheckTile = ({ intl }) => {
  return (
    <div className="quickcheck-info-container">
      <div className="quickcheck-content">
        <h2>
          <FormattedMessage id="pages.credit-success-toolkit.quickcheck.title" />
        </h2>
        <p data-testid="quickcheck-tile-description">
          <FormattedMessage id="pages.credit-success-toolkit.quickcheck.description" />
        </p>
        <Link
          to={appendExternalID(intl.formatMessage({ id: `pages.credit-success-toolkit.quickcheck.pre-approved-link` }))}
          isButton
          preset="primary-alt"
          data-testid="quickcheck-tile-button"
        >
          <FormattedMessage id="pages.credit-success-toolkit.quickcheck.button" />
        </Link>
      </div>
      <div className="quickcheck-image-box"></div>
    </div>
  );
};

QuickCheckTile.propTypes = {
  intl: intlShape,
};

const LegalDisclaimer = ({ intl }) => {
  return (
    <section id="legal-disclaimer-content">
      <AccordionItem
        title={<h2>{getContentKey(intl, `legal-disclaimer.title`)}</h2>}
        id="legal-info-accordion"
        onFocus={() => {}}
        onBlur={() => {}}
        isIndependent
      >
        <div>
          <p className="quickcheck-preapproval">
            <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1" values={{ sup1: <sup>1</sup> }} />
          </p>
          <ol type="a">
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-1" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-2" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-3" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-4" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-5" />
            </li>
          </ol>
          <p>
            <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p1-6" values={{ md: <sup>MD</sup> }} />
          </p>
          <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p3" values={{ sup3: <sup>2</sup> }} />
          <ul>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p3-1" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p3-2" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p3-3" />
            </li>
            <li>
              <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p3-4" />
            </li>
          </ul>
          <p data-testid="legal-trademark">
            <FormattedMessage
              id="pages.credit-success-toolkit.legal-disclaimer.mastercard"
              values={{ currentYear: <span>{new Date().getFullYear()}</span> }}
            />
          </p>
        </div>
      </AccordionItem>
    </section>
  );
};

LegalDisclaimer.propTypes = {
  intl: intlShape,
};

const CreditSuccessToolkit = injectIntl(({ intl }) => {
  const creditSuccessHeader = () => {
    return <Header intl={intl} />;
  };

  const creditSuccessFooter = () => {
    return <PdpFooterLH intl={intl} hidePromo={true} />;
  };

  return (
    <LayoutPDP
      containerClass="product-suite-container"
      CustomHeader={creditSuccessHeader}
      CustomFooter={creditSuccessFooter}
    >
      <LayoutWrapper className="credit-toolkit-layout-wrapper">
        <CreditToolkitHeader />
      </LayoutWrapper>
      <LayoutWrapper className="approval-card-tiles-layout-wrapper pdp-gray-background">
        <ApprovalCardTiles intl={intl} />
      </LayoutWrapper>
      <LayoutWrapper className="card-requirements-wrapper-product-suite pdp-gray-background">
        <CardRequirements intl={intl} />
      </LayoutWrapper>
      <LayoutWrapper className="credit-keeper-wrapper-product-suite pdp-gray-background">
        <CreditKeeperInfo intl={intl} />
      </LayoutWrapper>
      <LayoutWrapper className="mobile-application-wrapper-product-suite pdp-gray-background">
        <MobileAppTile intl={intl} />
      </LayoutWrapper>
      <LayoutWrapper className="card-tile-secure-layout-wrapper-product-suite pdp-gray-background">
        <CardTileSecureLH
          titleLeft={intl.formatMessage({ id: 'pages.credit-success-toolkit.card-tiles.c1' })}
          descriptionLeft={intl.formatMessage({ id: 'pages.credit-success-toolkit.card-tiles.p1' })}
          titleRight={intl.formatMessage({ id: 'pages.credit-success-toolkit.card-tiles.c2' })}
          descriptionRight={intl.formatMessage({ id: 'pages.credit-success-toolkit.card-tiles.p2' })}
        />
      </LayoutWrapper>
      <LayoutWrapper className="credit-management-wrapper-product-suite pdp-gray-background">
        <CreditManagement intl={intl} />
      </LayoutWrapper>
      <LayoutWrapper className="reviews-layout-wrapper-product-suite pdp-gray-background">
        <ReviewsBanner wrapperC intl={intl} />
      </LayoutWrapper>
      <LayoutWrapper className="quickcheck-layout-wrapper pdp-gray-background">
        <QuickCheckTile intl={intl} />
      </LayoutWrapper>
      <LayoutWrapper className={`legal-disclaimer-layout-wrapper-product-suite extra-space-bottom pdp-gray-background`}>
        <LegalDisclaimer intl={intl} />
      </LayoutWrapper>
    </LayoutPDP>
  );
});

export default CreditSuccessToolkit;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.credit-success-toolkit.seo.page-title"
    descriptionKey="pages.credit-success-toolkit.seo.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
